import { createNetworkApi, toQuery } from '@lib/util/networkRequest';

import apiInstance from '../instances/drupal';
import { formatGame } from '@/util/formatters';

const gameQueryFull = {
  fields: {
    'node--game': [ 
      'drupal_internal__nid',
      'body', 
      'title',
      'sticky',
      'created',
      'changed',
      'path',
      'status',
      'field_featured',
      'field_ratingb', 
      'field_total_votes', 
      'field_video_thumbnail', 
      'field_thumbnail', 
      'field_promo_image',
      'field_category',
      'field_link',
      'field_hide_from_listings',
      'field_likes',
      'field_amazon_store_link',
      'field_apple_app_store_link',
      'field_google_play_store_link',
      'field_cross_promotion',
      'field_more_io_games',
      'field_mobile',
      'field_youtube_links',
      'field_featured_on',
    ],
  },
  include: [ 
    'field_video_thumbnail', 
    'field_thumbnail', 
    'field_category',
    'field_promo_image',
  ]
};

const gameQuerySimple = {
  fields: {
    'node--game': [ 
      'title',
      'sticky',
      'created',
      'status',
      'drupal_internal__nid',
      'field_featured',
      'field_ratingb', 
      'field_total_votes', 
      'field_video_thumbnail', 
      'field_thumbnail',
      'field_promo_image',
      'field_category',
      'field_link',
      'field_hide_from_listings',
      'field_mobile',
      'path'
    ],
  },
  include: [ 
    'field_video_thumbnail', 
    'field_thumbnail', 
    'field_category',
    'field_promo_image',
  ]
};

export default createNetworkApi (apiInstance, {
  getById: (gameId, trackView) => ({
    method: 'get',
    url: toQuery(`/jsonapi/node/game/${gameId}`, {
      trackGameView: trackView ? 1 : 0,
      ...gameQueryFull
    }),
    transform: (result) => formatGame(result.data, result.included, result.meta)
  }),

  listAll: (limit = process.env.APP_CONFIG.DEFAULT_GAME_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
        ...gameQuerySimple,
        filter: {
          'status': 1,
          'field_hide_from_listings': 0
        },
        sort: '-field_ratingb',
        page: {
          limit,
          offset
        }
      }),
    transform: (result) => result.data.map((game) => formatGame(game, result.included, result.meta))
  }),

  listNew: (limit = process.env.APP_CONFIG.DEFAULT_GAME_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
        ...gameQuerySimple,
        filter: {
          'status': 1,
          'field_hide_from_listings': 0
        },
        sort: '-created,-field_ratingb',
        page: {
          limit,
          offset
        }
      }),
    transform: (result) => result.data.map((game) => formatGame(game, result.included, result.meta))
  }),

  listByCategory: (categoryId, limit = process.env.APP_CONFIG.DEFAULT_GAME_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
        ...gameQuerySimple,
        filter: {
          'status': 1,
          'field_hide_from_listings': 0,
          'field_category.id': categoryId
        },
        sort: '-field_ratingb',
        page: {
          limit,
          offset
        }
      }),
    transform: (result) => {
      return result.data.map((game) => formatGame(game, result.included, result.meta))
    }
  }),

  listFeaturedOrSticky: (limit = process.env.APP_CONFIG.DEFAULT_GAME_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
        ...gameQuerySimple,
        filter: {
          'status': 1,
          'field_hide_from_listings': 0,
          'featured': {
            'group': {
              'conjunction': 'OR'
            }
          },
          'field_featured': {
            'condition': {
              'path': 'field_featured',
              'value': 1,
              'memberOf': 'featured'
            }
          },
          'sticky': {
            'condition': {
              'path': 'sticky',
              'value': 1,
              'memberOf': 'featured'
            }
          }
        },
        sort: '-field_featured_on,-field_featured,-field_ratingb',
        page: {
          limit,
          offset
        }
      }),
    transform: (result) => result.data.map((game) => formatGame(game, result.included, result.meta))
  }),
  
  listSticky: (limit = process.env.APP_CONFIG.DEFAULT_GAME_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
        ...gameQuerySimple,
        filter: {
          'status': 1,
          'field_hide_from_listings': 0,
          'sticky': 1
        },
        sort: '-field_ratingb',
        page: {
          limit,
          offset
        }
      }),
    transform: (result) => result.data.map((game) => formatGame(game, result.included, result.meta))
  }),

  listFeatured: (limit = process.env.APP_CONFIG.DEFAULT_GAME_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
        ...gameQuerySimple,
        filter: {
          'status': 1,
          'field_hide_from_listings': 0,
          'field_featured': 1
        },
        sort: '-field_ratingb',
        page: {
          limit,
          offset
        }
      }),
    transform: (result) => result.data.map((game) => formatGame(game, result.included, result.meta))
  }),

  listPopular: (limit = process.env.APP_CONFIG.DEFAULT_GAME_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
        ...gameQuerySimple,
        filter: {
          'status': 1,
          'field_hide_from_listings': 0
        },
        sort: '-field_likes,-field_ratingb',
        page: {
          limit,
          offset
        }
      }),
    transform: (result) => result.data.map((game) => formatGame(game, result.included, result.meta))
  }),

  listPopularAlt: (limit = process.env.APP_CONFIG.DEFAULT_GAME_LIMIT, offset = 0) => ({
    method: 'get',
    url: `/iog/api/popular-games?page[limit]=${limit}&page[offset]=${offset}`,
    transform: (result) => result.map((game) => formatGame(game, undefined, undefined))
  }),

  listTopRated: (limit = process.env.APP_CONFIG.DEFAULT_GAME_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
        ...gameQuerySimple,
        filter: {
          'status': 1,
          'field_hide_from_listings': 0,
          'field_total_votes': {
            'operator': '>',
            'value': 30
          }
        },
        sort: '-field_ratingb',
        page: {
          limit,
          offset
        }
      }),
    transform: (result) => result.data.map((game) => formatGame(game, result.included, result.meta))
  }),

  listByTitle: (title, limit = process.env.APP_CONFIG.DEFAULT_GAME_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
        ...gameQuerySimple,
        filter: {
          status: 1,
          'title-filter': {
            condition: {
              path: 'title',
              operator: 'CONTAINS',
              value: title
            }
          }
        },
        sort: '-field_ratingb',
        page: {
          limit,
          offset
        }
      }),
    transform: (result) => result.data.map((game) => formatGame(game, result.included, result.meta))
  }),

  listByDeveloper: (developerId, limit = process.env.APP_CONFIG.DEFAULT_GAME_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
        ...gameQuerySimple,
        filter: {
          'uid.id': developerId,
        },
        sort: '-field_ratingb',
        page: {
          limit,
          offset
        }
      }),
    transform: (result) => result.data.map((game) => formatGame(game, result.included, result.meta))
  }),

  listByDeveloperPublishedOnly: (developerId, limit = process.env.APP_CONFIG.DEFAULT_GAME_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
        ...gameQuerySimple,
        filter: {
          'uid.id': developerId,
          'status': 1,
          'field_hide_from_listings': 0
        },
        sort: '-field_ratingb',
        page: {
          limit,
          offset
        }
      }),
    transform: (result) => result.data.map((game) => formatGame(game, result.included, result.meta))
  }),

  publishGame: (data) => ({
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    url: `/node`,
    params: {
      _format: 'json'
    },
    data,
    transform: (data) => data
  }),

  updateGame: (id, token, attributes, relationships) => ({
    method: 'patch',
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
      'X-CSRF-Token': token
    },
    url: `/jsonapi/node/game/${id}`,
    data: {
      data: {
        id,
        type: 'node--game',
        attributes,
        relationships
      }
    },
    transform: (data) => data
  }),

  getRatingForUser: (gameNid, userId) => ({
    url: '/iog/api/vote/user-status',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      _format: 'json'
    },
    data: {
      entity_type: 'node',
      entity_id: gameNid,
      user_id: userId,
    },
    transform: (data) => data
  }),

  rateGame: (gameId, userId, rating) => ({
    url: '/iog/api/vote/cast',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      _format: 'json'
    },
    data: {
      entity_type: 'node',
      entity_id: gameId,
      user_id: userId,
      vote_value: rating
    },
    transform: (data) => data
  })
})
