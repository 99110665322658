import { If, createComponent } from '@lib/util/templateHelpers';
import shared from '@lib/components';
const { Flex, Button, RouterLink, Container, Ad } = shared;
import { useAuthStore } from '@/stores/auth';
import { useLogout } from '@/hooks/logout';

import styles from './index.module.scss';

const appFooterClassStates = [
  'hasBannerAd',
  'collapsed'
];

export default createComponent('AppFooter', { classStates: appFooterClassStates, styles }, function AppFooter ({ className }, props) {
  const [ state ] = useAuthStore();

  const logout = useLogout();

  return (
    <div className={className}>
      <footer className='AppFooter__Inner'>
        <Container wide noPadding={props.collapsed}>
          <Flex wide justifySpaceAround>
            <Flex container>
              <Flex gap1>
                <p>
                  © {(new Date()).getFullYear()} iogames.space. All rights reserved.
                </p>
              </Flex>
            </Flex>
            <Flex container>
              <Flex gap1>
                <Flex><RouterLink href='/privacy-policy'>Privacy Policy</RouterLink></Flex>
                <Flex><RouterLink href='/terms-and-conditions'>Terms of Service</RouterLink></Flex>
                <Flex><RouterLink href='/contact-us'>Contact</RouterLink></Flex>
                <Flex><RouterLink href='/feature-your-io-game'>Get Your Game Featured</RouterLink></Flex>
              </Flex>
            </Flex>
            <Flex container>
              <Flex gap1>
                {
                    If(state.ready && !!state.auth, () => (<>
                      <Flex><Button.Link text href='/account/edit'>My Account</Button.Link></Flex>
                      <Flex><Button text onClick={logout}>Sign Out</Button></Flex>
                      <Flex><Button.Link text href='/developer'>For Developers</Button.Link></Flex>
                    </>))
                    .EndIf()
                  }
                  {
                    If(state.ready && !state.auth, () => (<>
                      <Flex><Button.Link text href='/account/register'>Sign Up</Button.Link></Flex>
                      <Flex><Button.Link text href='/account'>Sign In</Button.Link></Flex>
                      <Flex><Button.Link text href='/account'>For Developers</Button.Link></Flex>
                    </>))
                    .EndIf()
                  }
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </footer>
      <div id='ymbp_usp_iogames_next'></div>
      {
        If(props.hasBannerAd, () => (
          <div className='AppFooter__BannerAd'>
            <Flex wide>
              <Flex center justifyCenter alignCenter>
                <Ad.Yolla 
                  type={Ad.Yolla.Type.DESKTOP_LEADERBOARD}
                  subType={[Ad.Yolla.SubType.DESKTOP_LEADERBOARD,Ad.Yolla.SubType.MOBILE_LEADERBOARD]}
                />
              </Flex>
            </Flex>
          </div>
        ))
        .EndIf()
      }
    </div>
  );
});
