import { For, createComponent } from '@lib/util/templateHelpers';
import shared from '@lib/components';
import styles from './index.module.scss';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
const { Flex, Button, Icon } = shared;

export default createComponent('AppHeader__Desktop__Menu', { styles }, function DesktopMenu ({ className }, props) {
  return (
    <Flex className={className} wide directionRow justifySpaceBetween alignCenter>
      {
        For(props.menuitems, (menuitem, index) => (
          <Button.Link text key={index} href={menuitem.target}><span>{menuitem.text}</span></Button.Link>
        ))
      }
      <a className='Button Button--primary' href='https://shop.shockwave.com/collections/iogames' target='_blank'>
        <Icon.FA icon={faShoppingBag} style={{ height:'1rem', width: '1rem' }} />&nbsp;&nbsp;
        <span>Merch</span>
      </a>
    </Flex>
  );
});
