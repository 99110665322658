import Link from 'next/link';

import { If, createComponent } from '@lib/util/templateHelpers';
import shared from '@lib/components';
const { Flex, Button, Container } = shared;

import styles from './index.module.scss';
import IoSearch from '@/components/IoSearch';
import DesktopMenu from '@/components/AppHeader/DesktopMenu';

const menuitems = process.env.APP_CONFIG.DESKTOP_MENU_LINKS;

export default createComponent('AppHeader__Desktop', { styles }, function DesktopHeader ({ className }, props) {
  return (
    <Container className={className} wide>
      <Flex wide justifySpaceBetween>
        <Flex alignCenter>
          {props.children}
          <Link href='/'>
            {/* Intentionally squished markup here so no whitespace */}
            <a className='AppHeader__Desktop__Logo' style={{fontSize: 0}}><img
              src='/images/Logo.svg'
              alt='iogames.space'
              width={190}
              height={28}
            /></a>
          </Link>
        </Flex>
        <Flex twoThirds>
          <DesktopMenu menuitems={menuitems}/>
        </Flex>
        <Flex>
          <IoSearch placeholder='Search io Games' />
        </Flex>
      </Flex>
    </Container>
  );
});
