import React from 'react';
import { useRouter } from 'next/router';

import { createComponent } from '@lib/util/templateHelpers';
import shared from '@lib/components';
const { 
  Autocomplete
} = shared;

import gameApi from '@/apis/drupal/game';

export default createComponent('IoSearch', {}, function GameTileBlock ({ className }, props) {
  const router = useRouter();

  const searchQuery = async (query) => {
    return await gameApi.listByTitle(query, 10, 0);
  };

  const onSearch = (query) => {
    router.push(`/search/${encodeURIComponent(query)}`);
  };

  const onSelect = (item) => {
    router.push(item.path);
  };

  const findItemPath = (item) => {
    return item.path;
  };

  return (
    <Autocomplete
      className={className}
      searchQuery={searchQuery}
      placeholder={props.placeholder || ''}
      findItemPath={findItemPath}
      onSearch={onSearch}
      onSelect={onSelect}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    />
  )
});
