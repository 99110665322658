import { useRouter } from 'next/router';
import { useAuthStore, deauthorize } from '@/stores/auth'; 

export function useLogout () {
  const router = useRouter()
  const [ _authState, authDispatch ] = useAuthStore();

  return function () {
    authDispatch(deauthorize, {});
  }
}
